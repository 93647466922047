import React from 'react';

import Contact from '../../../components/contact';
import Hero from '../../../components/hero';
import Layout from '@src/components/layout';

import * as styles from './savings.module.css';

import villkor_for_inlaning from '../../../../static/pdfs/Inlaningsvillkor_nov2021.pdf';

const VilkorForInlaning = () => {
  return (
    <Layout newStyle>
      <Hero
        title="Rocker Spar villkor för inlåning"
        subtitleMarkdown="Klicka på länkarna nedan för att ladda ner"
      />

      <div className="container">
        <div
          style={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <a className={styles.link} href={villkor_for_inlaning} target="blank">
            Villkor för inlåning
          </a>
        </div>
      </div>
      <Contact />
    </Layout>
  );
};

export default VilkorForInlaning;
